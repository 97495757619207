import React from "react";
import {
  InternalExternalLink,
  InternalExternalLinkProps
} from "src/components/global/internalExternalLink";
import { Image } from "src/components/image";

export interface HeroModuleProps {
  data: {
    image: {
      _id: string;
      _ref: string;
    };
    headline: string;
    cta: InternalExternalLinkProps[];
  };
}

export const HeroModule = ({ data }: HeroModuleProps) => {
  const { image, headline, cta } = data;

  return (
    <div className="relative">
      <Image
        imageId={image.asset._id || image.asset._ref}
        className="h-75vh md:h-50vh overflow-hidden"
        imgClassName="h-full"
      />
      <div className="text-center text-white font-display absolute inset-0 flex items-center">
        <div className="max-w-2xl  mx-auto">
          <h1 className="text-3xl md:text-4xl lg:text-5xl leading-tight mb-8">
            {headline}
          </h1>
          <InternalExternalLink data={cta[0]} className="btn--primary" />
        </div>
      </div>
    </div>
  );
};
