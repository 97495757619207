import React from "react";

export interface HeroDescriptionProps {
  data: {
    headline: string;
    body: string;
  };
}

export const HeroDescription = ({ data }: HeroDescriptionProps) => {
  const { headline, body } = data;
  return (
    <div className="text-center max-w-2xl mx-auto my-20 px-4">
      <h2 className="text-2xl md:text-3xl mb-4">{headline}</h2>
      <p>{body}</p>
    </div>
  );
};
