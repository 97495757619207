import React from "react";

import BlockContent from "@sanity/block-content-to-react";

import { Serializer } from "src/utils/serializer";

export interface StandardTextProps {
  data: {
    text: any[];
  };
}

export const StandardText = ({ data }: StandardTextProps) => {
  const { text } = data;
  return (
    <div className="max-w-4xl mx-auto py-8 px-4 w-full blockContent">
      <BlockContent blocks={text} serializers={Serializer} />
    </div>
  );
};
