import React, { useState, useEffect } from "react";

import { PageLink } from "src/components/link";
import { Image } from "src/components/image";
import { ProductInt } from "src/interfaces/product";
import { ProductForm } from "./form";

import { client, useAddItemToCart } from "src/context/siteContext";

export const ProductCard = ({
  _id,
  content: { main, shopify }
}: ProductInt) => {
  return (
    <div className="w-full md:w-2/6 p-4 text-center" key={_id}>
      <PageLink className="block" to={`/products/${main.slug.current}`}>
        {shopify.image && (
          <Image imageId={shopify.image.asset._ref} alt={main.title} />
        )}
        <h2 className="text-lg mt-3">{main.title}</h2>
        <span>${shopify.defaultPrice}</span>
      </PageLink>
    </div>
  );
};
