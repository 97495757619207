import React from "react";

import { ProductCard } from "src/components/product/card";
import { ProductInt } from "src/interfaces/product";

export interface ProductGridProps {
  data: {
    title?: string;
    shortDescription?: string;
    products?: [ProductInt];
  };
}

export const ProductGrid = ({ data }: ProductGridProps) => {
  const { title, products, shortDescription } = data;
  return (
    <div className="max-w-6xl mx-auto px-4 my-10">
      {title && (
        <h3 className="text-lg md:text-xl text-center my-2">{title}</h3>
      )}
      {shortDescription && <p className="text-center">{shortDescription}</p>}
      <div className="flex flex-wrap max-w-6xl mx-auto justify-center">
        {products &&
          products.map(singleProduct => (
            <ProductCard key={singleProduct._id} {...singleProduct} />
          ))}
        <div className="x c30" />
      </div>
    </div>
  );
};
