import React from "react";
import { PageLink } from "src/components/link";
import {
  InternalLinkInt,
  ExternalLinkInt
} from "src/components/interfaces/link";

export interface InternalExternalLinkProps {
  data: InternalLinkInt | ExternalLinkInt;
  className?: string;
}

export const InternalLink = ({
  data,
  className
}: {
  data: InternalLinkInt;
  className?: string;
}) => {
  const { title, link } = data;
  const slug = link.content.main.slug.current;
  let to;
  switch (link._type) {
    case "page":
      to = `/${slug}/`;
      break;
    case "product":
      to = `/products/${slug}/`;
      break;
    case "collection":
      to = `/collection/${slug}/`;
      break;
    default:
      to = `/${slug}/`;
  }
  return (
    <PageLink to={to} className={className}>
      {title}
    </PageLink>
  );
};

export const InternalExternalLink = ({
  data,
  className
}: InternalExternalLinkProps) => {
  const { _type, title, link } = data;
  return (
    <>
      {_type === "internalLink" && (
        <InternalLink data={data} className={className} />
      )}
      {_type === "externalLink" && (
        <PageLink to={link} className={className}>
          {title}
        </PageLink>
      )}
    </>
  );
};
