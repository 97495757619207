import React from "react";
import cx from "classnames";
import BlockContent from "@sanity/block-content-to-react";
import { Image } from "src/components/image";
import {
  InternalExternalLink,
  InternalExternalLinkProps
} from "src/components/global/internalExternalLink";

export interface FiftyFiftyProps {
  data: {
    image: {
      _id: string;
      _ref: string;
    };
    keepImageInline?: boolean;
    textSection: {
      label?: string;
      headline?: string;
      body?: any[];
      link?: InternalExternalLinkProps[];
      layout?: "left" | "center" | "right";
    };
    layout?: "imageText" | "textImage";
  };
}

export const FiftyFifty = ({ data }: FiftyFiftyProps) => {
  const { image, textSection, layout, keepImageInline } = data;
  const { label, headline, body, link, layout: textLayout } = textSection;

  return (
    <div
      className={cx("flex md:flex-row items-center bg-white", {
        "flex-col": !!!keepImageInline,
        "md:flex-row-reverse": layout === "textImage"
      })}
    >
      <div
        className={cx("flex-1 w-full", {
          "self-end": keepImageInline
        })}
      >
        <Image
          imageId={image.asset._id || image.asset._ref}
          alt={headline}
          imgClassName={cx({
            "max-w-sm": keepImageInline,
            "ml-auto": keepImageInline && layout === "imageText"
          })}
        />
      </div>
      <div
        className={cx(`flex-1 text-left`, {
          "text-right": textLayout === "right",
          "text-center": textLayout === "center"
        })}
      >
        <div
          className={cx("max-w-lg p-6 mb-6", {
            "md:ml-auto": layout === "textImage",
            "md:mr-auto": layout === "imageText"
          })}
        >
          {headline && (
            <h2 className="font-display text-xl md:text-2xl">{headline}</h2>
          )}
          {body && <BlockContent blocks={body} />}
          {link && link[0] ? (
            <InternalExternalLink data={link[0]} className="btn--link" />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
