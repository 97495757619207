import React from "react";

import {
  StandardText,
  StandardTextProps
} from "src/components/global/standardText";
import {
  ProductGrid,
  ProductGridProps
} from "src/components/global/productGrid";
import {
  NestedPages,
  NestedPagesProps
} from "src/components/global/nestedPages";
import {
  ImageModule,
  ImageModuleProps
} from "src/components/global/imageModule";
import { HeroModule, HeroModuleProps } from "src/components/global/hero";
import { FiftyFifty, FiftyFiftyProps } from "src/components/global/fiftyFifty";
import {
  HeroDescription,
  HeroDescriptionProps
} from "src/components/global/heroDescription";

export const Modules = ({
  reactModule,
  type
}: {
  type: string;
  reactModule: any;
}) => {
  switch (type) {
    case "standardText":
      return <StandardText data={reactModule as StandardTextProps["data"]} />;
    case "productGrid":
      return <ProductGrid data={reactModule as ProductGridProps["data"]} />;
    case "nestedPages":
      return <NestedPages data={reactModule as NestedPagesProps["data"]} />;
    case "imageModule":
      return <ImageModule data={reactModule as ImageModuleProps["data"]} />;
    case "heroModule":
      return <HeroModule data={reactModule as HeroModuleProps["data"]} />;
    case "fiftyFiftyModule":
      return <FiftyFifty data={reactModule as FiftyFiftyProps["data"]} />;
    case "heroDescriptionModule":
      return (
        <HeroDescription data={reactModule as HeroDescriptionProps["data"]} />
      );
    default:
      return <span>{type}</span>;
  }
};
